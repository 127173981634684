<template>
	<v-dialog
			class="popup"
			v-model="dialog"
			width="400"
			@click:outside="onDialogClose"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
					x-large
					v-bind="attrs"
					v-on="on"
					color="error"
					min-width="175"
			>
				Delete Account
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2 mb-4">
				<strong>Are you sure you want to delete your account?</strong>
			</v-card-title>

			<div class="popup__content">
				<p class="popup__text">
					You can delete your Account at any time. If you change your mind, you will not be able to
					recover it.
				</p>

				<p class="popup__text">
					All your {{ BRAND_NAME }} will be automatically deleted as well.
				</p>
			</div>

			<v-divider></v-divider>

			<v-card-actions class="popup__actions">
				<v-btn
						type="button"
						@click="onDialogClose"
				>
					Cancel
				</v-btn>
				<v-btn
						type="submit"
						@click.prevent="onSubmit"
						:disabled="auth.userAccountDelete.isLoading"
						:loading="auth.userAccountDelete.isLoading"
						color="error"
				>
					Yes
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mixinBrandName } from "../../mixins/mixinBrandName";
	import {
		mapActions,
		mapState
	} from "vuex";


	export default {
		name: "DeleteAccount",
		mixins: [mixinBrandName],
		data() {
			return {
				dialog: false,
			};
		},
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		methods: {
			...mapActions( [
				'requestUserAccountDelete',
			] ),
			onDialogClose: function () {
				this.dialog = false;
			},
			onSubmit: function () {
				this.$store.dispatch( "requestUserAccountDelete" );
			},
		}
	};
</script>
