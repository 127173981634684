var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"popup",attrs:{"width":"400"},on:{"click:outside":_vm.onDialogClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","min-width":"175"}},'v-btn',attrs,false),on),[_vm._v(" Change Password ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 mb-4"},[_c('strong',[_vm._v("Change Password")])]),_c('v-form',{ref:"form",staticClass:"popup__form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"popup__content"},[_c('v-text-field',{attrs:{"label":"Old Password","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.password.lowercase,
                          _vm.validationRules.password.uppercase,
                          _vm.validationRules.password.digit,
                          _vm.validationRules.password.min ],"required":"","append-icon":_vm.passwordOldShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passwordOldShow ? 'text' : 'password',"color":"none"},on:{"click:append":function($event){_vm.passwordOldShow = !_vm.passwordOldShow},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordOld),callback:function ($$v) {_vm.passwordOld=$$v},expression:"passwordOld"}}),_c('v-text-field',{attrs:{"label":"New Password","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.password.lowercase,
                          _vm.validationRules.password.uppercase,
                          _vm.validationRules.password.digit,
                          _vm.validationRules.password.min ],"required":"","append-icon":_vm.passwordNewShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passwordNewShow ? 'text' : 'password',"color":"none"},on:{"click:append":function($event){_vm.passwordNewShow = !_vm.passwordNewShow},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordNew),callback:function ($$v) {_vm.passwordNew=$$v},expression:"passwordNew"}}),_c('v-text-field',{attrs:{"type":"password","label":"Confirm New Password","rules":[
                          _vm.validationRules.required,
                          _vm.passwordNew === _vm.passwordNewConfirmation || 'Password must match'
                        ],"required":"","color":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordNewConfirmation),callback:function ($$v) {_vm.passwordNewConfirmation=$$v},expression:"passwordNewConfirmation"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"popup__actions"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.onDialogClose}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":!_vm.valid || _vm.auth.passwordChange.isLoading,"loading":_vm.auth.passwordChange.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Submit ")])],1),_c('v-divider'),_c('div',{staticClass:"popup__footer"},[_c('p',{staticClass:"popup__text"},[_vm._v(" If you forgot your Old Password, please "),_c('br'),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.onResetPassword($event)}}},[_vm._v("Reset Password")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }