<template>
	<Page class="page-my-account">
		<Title title="My Account" />

		<section v-if="user.data" class="sections l--content">
			<div class="section">
				<h2 class="section__title">Basic info</h2>
				<ul class="list">
					<li class="list-row">
						<span class="list-row-title">Username</span>
						<span class="list-row-value">{{ user.data.name }}</span>
					</li>
					<li class="list-row">
						<span class="list-row-title">Email</span>
						<span class="list-row-value">{{ user.data.email }}</span>
					</li>
				</ul>
			</div>

			<div class="section">
				<h2 class="section__title">Privacy</h2>
				<ul class="list">
					<li class="list-row">
						<span class="list-row-title">Password</span>
						<ChangePassword />
					</li>
					<li class="list-row">
						<span class="list-row-title">Account</span>
						<DeleteAccount />
					</li>
				</ul>
			</div>
		</section>
	</Page>
</template>

<script>
	import Page from "../../components/layouts/LayoutPage";
	import Title from "../../components/common/Title";
	import { mapState } from "vuex";
		import ChangePassword from "./ChangePassword";
		import DeleteAccount from "./DeleteAccount";


	export default {
		name: "MyAccount",
		components: { DeleteAccount, ChangePassword, Title, Page },
		computed: mapState( [
			'user'
		] )
	};
</script>

<style scoped lang="scss">
	.section {
		margin-bottom: 10rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.section__title {
		font-size: 2rem;
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid $c-gray-primary;
		color: $c-gray-secondary;
	}

	.list-row {
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
		font-size: 1.6rem;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include breakpoint(phone) {
			justify-content: space-between;
		}
	}

	.list-row-title {
		@include breakpoint(overPhone) {
			min-width: 25rem;
		}
	}

	.list-row-value {
		font-style: italic;
		opacity: .8;
	}
</style>
