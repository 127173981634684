<template>
	<v-dialog
			class="popup"
			v-model="dialog"
			width="400"
			@click:outside="onDialogClose"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
					x-large
					v-bind="attrs"
					v-on="on"
					min-width="175"
			>
				Change Password
			</v-btn>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2 mb-4">
				<strong>Change Password</strong>
			</v-card-title>

			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
					class="popup__form"
			>
				<div class="popup__content">
					<v-text-field
							v-model="passwordOld"
							label="Old Password"
							:rules="[
                          validationRules.required,
                          validationRules.password.lowercase,
                          validationRules.password.uppercase,
                          validationRules.password.digit,
                          validationRules.password.min,
                        ]"
							required
							@click:append="passwordOldShow = !passwordOldShow"
							:append-icon="passwordOldShow ? 'mdi-eye' : 'mdi-eye-off'"
							:type="passwordOldShow ? 'text' : 'password'"
							color="none"
							@keydown.space.prevent
					></v-text-field>
					<v-text-field
							v-model="passwordNew"
							label="New Password"
							:rules="[
                          validationRules.required,
                          validationRules.password.lowercase,
                          validationRules.password.uppercase,
                          validationRules.password.digit,
                          validationRules.password.min,
                        ]"
							required
							@click:append="passwordNewShow = !passwordNewShow"
							:append-icon="passwordNewShow ? 'mdi-eye' : 'mdi-eye-off'"
							:type="passwordNewShow ? 'text' : 'password'"
							color="none"
							@keydown.space.prevent
					></v-text-field>
					<v-text-field
							v-model="passwordNewConfirmation"
							type="password"
							label="Confirm New Password"
							:rules="[
                          validationRules.required,
                          passwordNew === passwordNewConfirmation || 'Password must match'
                        ]"
							required
							color="none"
							@keydown.space.prevent
					></v-text-field>
				</div>

				<v-divider></v-divider>

				<v-card-actions class="popup__actions">
					<v-btn
							type="button"
							@click="onDialogClose"
					>
						Cancel
					</v-btn>
					<v-btn
							type="submit"
							@click.prevent="onSubmit"
							color="success"
							:disabled="!valid || auth.passwordChange.isLoading"
							:loading="auth.passwordChange.isLoading"
					>
						Submit
					</v-btn>
				</v-card-actions>

				<v-divider></v-divider>

				<div class="popup__footer">
					<p class="popup__text">
						If you forgot your Old Password, please
						<br>
						<button @click.prevent="onResetPassword">Reset Password</button>
					</p>
				</div>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mixinValidationRules } from "../../mixins/mixinValidationRules";
	import {
		mapActions,
		mapState
	} from "vuex";
	import { mixinPaths } from "../../mixins/mixinPaths";


	export default {
		name: "ChangePassword",
		mixins: [
			mixinValidationRules,
			mixinPaths,
		],
		data() {
			return {
				dialog: false,
				valid: false,
				passwordOld: '',
				passwordOldShow: false,
				passwordNew: '',
				passwordNewShow: false,
				passwordNewConfirmation: '',
			};
		},
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		methods: {
			...mapActions( [
				'requestPasswordChange',
				'requestPasswordChangeReset',
				'requestLogout',
			] ),
			onDialogClose: function () {
				this.dialog = false;
				this.$refs.form.reset();
			},
			onSubmit() {
				if ( this.$refs.form.validate() ) {
					this.$store.dispatch(
						"requestPasswordChange",
						{
							old_pass: this.passwordOld,
							password: this.passwordNew,
							password_confirmation: this.passwordNewConfirmation,
						}
					).then( () => {
						if ( this.auth.passwordChange.success ) {
							this.onDialogClose();
						}
					} );
				}
			},
			onResetPassword() {
				this.$store.dispatch( 'requestLogout', { redirectPath: this.paths().passwordResetLink } );
			}
		},
		destroyed() {
			this.$store.dispatch( "requestPasswordChangeReset" );
		},
	};
</script>
<style lang="scss" scoped>
	.popup__footer {
		padding: 2rem 2rem 1rem;
		text-align: center;

		a,
		button {
			color: $c-brand-primary;
		}
	}
</style>
